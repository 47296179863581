import {
    CSSTransition,
    TransitionGroup,
} from 'react-transition-group';
import { Offer, MarketPack } from "../../types/offers";
import { openLink, sortByPrice } from '../../utils/Utils';
import { FC } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

interface BookRowsProps {
    marketAsks: Offer[]
    marketBids: Offer[]
    type: 'asks' | 'bids'
    count: number
}
interface BookRowsSkeletonProps {
    count: number
}

function getMark(total: number, percent: number, isVerified: Boolean){
    if (isVerified){
        return `${total} ✅`
    }
    if (percent < 80){
        return `${total} ❌`
    }
    return `${total} 🔸`
}

const BookRows: FC<BookRowsProps> = ({marketAsks, marketBids, type, count}) => {
    let colorClass = 'green'
    let asks = sortByPrice(marketAsks).slice(0, count).reverse()
    let bids = sortByPrice(marketBids, true).slice(0, count).reverse()
    let data = [] as Offer[]
    if (type === 'asks'){
        colorClass = 'red'
        data = asks.reverse()
    }
    else{
        data = bids
    }

    let topVolume = 0
    for(const o of asks as Offer[]){
        if (o.availableVolume > topVolume){
            topVolume = o.availableVolume
        }
    }
    for(const o of bids as Offer[]){
        if (o.availableVolume > topVolume){
            topVolume = o.availableVolume
        }
    }

    return(
        <>  
            <TransitionGroup className="action-block">
                {
                    data.map((row: Offer) => (
                        <CSSTransition
                            key={row.id}
                            nodeRef={row.nodeRef}
                            timeout={1}
                            classNames="item">
                                
                                <div ref={row.nodeRef} onClick={()=>{openLink(row.id, row.user.userId)}} className="book_row panel_decorate">
                                    <div className={"book_table price " + colorClass}>{row.price.value.toFixed(2)}</div>
                                    <div className="book_table volume">{row.availableVolume.toFixed(2)}</div>
                                    <div className="book_table summary">{(row.price.value * row.availableVolume).toFixed(2)}</div>

                                    <div className="book_table user">
                                        <p>{row.user.nickname}</p>
                                    </div>
                                    <div className="book_table stats">
                                        {getMark(row.user.statistics.totalOrdersCount, row.user.statistics.successPercent, row.user.isVerified)}
                                    </div>
                                    <div className={type==='asks'?'filler_red':'filler_green'} 
                                    style={{ width: row.availableVolume/topVolume*100<50?row.availableVolume/topVolume*100+row.availableVolume/topVolume*100*5:row.availableVolume/topVolume*100 + '%'}}></div>
                                </div>

                        </CSSTransition>
                    ))
                }
            </TransitionGroup>
        </>
    )
}

const BookRowsSkeleton: FC<BookRowsSkeletonProps> = ({count}) => {
    const array = [...Array(count)]
    return (
        <SkeletonTheme height={'29px'} baseColor="#202020" highlightColor="#444">
            <div className="action-block">
                {
                    array.map((e, i) => (
                        <div key={i} className="item">
                            <Skeleton style={{margin:"2px 0px"}}/>
                        </div>
                    ))
                }
            </div>
        </SkeletonTheme>
    )
}


function BookHead(){

    return(
        <div className="book_panel">
            <div className="book_panel_2 panel_decorate panel_info">
            <div className="book_table price">Price</div>
            <div className="book_table volume">Volume</div>
            <div className="book_table summary">Summary</div>
            <div className="book_table user">User</div>
            <div className="book_table stats">Stats</div>
            </div>
        </div>
    )
}



export {BookRows, BookHead, BookRowsSkeleton}